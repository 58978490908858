import styled from 'styled-components'

// interface ItemProps {
//   bg?: any;
// }

export const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`

export const Item = styled.div`
  width: auto;

  height: auto;

  z-index: 99;
  background: #000;
  font-size: 12px;
  color: #fff;
  display: block;
  padding: 10px;
  &.false {
    display: none;
  }
  .content {
    margin: -6px 1px 2px;
    position: relative;
    .box {
      position: absolute;
      top: 0px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cbcbcc;
      font-size: 600;
    }
    .text {
      line-height: 20px;
    }
  }
  .button {
    width: 100px;
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
`
