import React, { useEffect, useMemo, useState } from 'react'
import * as S from './style'
import { Button3, Button4, Button5, Button6 } from 'components'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import moneyConvert, { moneyConvertToNumber } from 'utils/moneyConvert'
import types from 'store/actions/types'
import { useParams } from 'react-router-dom'
import { bettingSports, meApi } from 'apis'
import { convertNewLine } from 'utils/convertNewLine'

type Props = {}

const dataButtonMoney = [
  { value: 5000, text: '5천' },
  { value: 10000, text: '1만' },
  { value: 50000, text: '5만' },
  { value: 100000, text: '10만' },
  { value: 500000, text: '50만' },
  { value: 1000000, text: '100만' }
]

function BetSlipSportsGame({}: Props): React.ReactElement {
  const dataMe = useSelector((state: any) => state.user)
  const { dataBetting } = useSelector((state: any) => state.bettingSports)
  const dispatch = useDispatch()
  const { id: type }: { id: string } = useParams()
  const currentTimezone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }, [])

  const [moneyBet, setMoneyBet] = useState(0)
  const [time, setTime] = useState(
    moment.tz(currentTimezone).format('HH:mm:ss')
  )

  const handleChangeBetMoney = (e: any) => {
    const { value } = e.target
    if (!isNaN(moneyConvertToNumber(value))) {
      const isPassMoney =
        dataMe?.dataUser?.mb_money - moneyConvertToNumber(value) < 0
      if (isPassMoney) {
        return dispatch({
          type: types.POPUP_ACTION_OPEN,
          payload: {
            msg: `배팅할 보유머니가 부족합니다..`,
            typePopup: 'CONFIRM'
          }
        })
      }
      setMoneyBet(moneyConvertToNumber(value))
    }
  }

  const handleReset = () => {
    setMoneyBet(0)
  }

  const handleClickButtonMoney = (e: any, money: number) => {
    const isPassMoney = dataMe?.dataUser?.mb_money - (moneyBet + money) < 0
    if (isPassMoney) {
      return dispatch({
        type: types.POPUP_ACTION_OPEN,
        payload: {
          msg: `배팅할 보유머니가 부족합니다..`,
          typePopup: 'CONFIRM'
        }
      })
    }

    setMoneyBet((prev) => prev + money)
  }

  const handleMaxMoneyBet = () => {
    setMoneyBet(dataMe.dataUser?.mb_money)
  }

  const handleDeleteOneBetting = (e: any, idx: string | number) => {
    dispatch({
      payload: idx,
      type: types.REMOVE_DATA_BETTING_SPORTS
    })
  }

  const handleResetAll = () => {
    dispatch({
      type: types.RESET_DATA_BETTING_SPORTS
    })
    setMoneyBet(0)
  }

  const loadDataUser = async () => {
    dispatch({ type: 'WAITTING' })
    try {
      const res: any = await meApi()
      if (res?.result === false) {
        alert(res?.message)
        dispatch({ type: 'STOP_WAITTING' })
        return
      }
      if (res?.data) {
        dispatch({ type: 'STOP_WAITTING' })
        dispatch({
          type: types.GET_DATA_USER,
          dataUser: res.data
        })
        return
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'STOP_WAITTING' })
    }
  }

  const onBetting = async () => {
    const mergeEachFieldToArr = dataBetting.reduce((acc: any, obj: any) => {
      for (const key in obj) {
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj[key])
      }
      return acc
    }, {})

    const { div_sel, game_type, idx, isCanBet, pick, ratio } =
      mergeEachFieldToArr

    const payload = {
      type: type,
      room: '',
      bettingcnt: dataBetting.length,
      bettingdiv: parseFloat(sumBettingDiv),
      bettingwin: moneyWin,
      idx_list: idx?.join('|'),
      pick_list: pick?.join('|'),
      betDiv_list: ratio?.join('|'),
      gameType_list: game_type?.join('|'),
      gmoney: dataMe.dataUser?.mb_money,
      bet_org_list: div_sel?.join('|'),
      betMoney: moneyConvert(moneyBet, true),
      idx: idx?.map((element: number) => String(element)),
      pick: pick?.map((element: string) => Number(element)),
      betDiv: ratio?.map((element: string) => Number(element)),
      gameType: game_type?.map((element: string) => Number(element)),
      bet_div_list: div_sel
    }

    if (isCanBet?.every((element: boolean) => element === true)) {
      try {
        dispatch({ type: 'WAITTING' })
        const res: any = await bettingSports(payload)

        if (res?.result) {
          handleResetAll()
          alert(res?.message)
          await loadDataUser()
        } else if (!res?.error?.result) {
          alert(convertNewLine(res?.error?.message))
        }
        dispatch({ type: 'STOP_WAITTING' })
      } catch (error) {
        console.log(error)
        dispatch({ type: 'STOP_WAITTING' })
      }
    }
  }

  const handleBetting = () => {
    dispatch({
      type: types.POPUP_ACTION_OPEN,
      payload: {
        msg: `배팅을 하시겠습니까?`,
        typePopup: 'ACTION',
        actionText: 'OK',
        action: onBetting
      }
    })
  }

  const sumBettingDiv = useMemo(() => {
    const sum: number = dataBetting.reduce(
      (accumulator: number, currentValue: any) => {
        return parseFloat(currentValue.ratio) + accumulator
      },
      0
    )

    return sum.toFixed(2)
  }, [dataBetting])

  const moneyWin = useMemo(() => {
    const mWin = parseFloat(sumBettingDiv) * moneyBet

    return mWin.toFixed()
  }, [sumBettingDiv, moneyBet])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment.tz(currentTimezone).format('HH:mm:ss'))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [currentTimezone])

  return (
    <S.Wrapper>
      <S.Header>
        <p>BETSLIP</p>
        <div>
          <span className='clock'>{time}</span>
          <Button3 onClick={handleResetAll} className='btn'>
            비우기
          </Button3>
        </div>
      </S.Header>
      <S.Betting>
        <S.Table>
          <colgroup>
            <col width='30%' />
            <col width='*' />
          </colgroup>
          <S.Tbody>
            <tr>
              <th>보유금액</th>
              <td>
                <p className='num'>
                  {moneyConvert(dataMe.dataUser?.mb_money, true)}
                </p>
              </td>
            </tr>
            <tr>
              <th>전체배당</th>
              <td>
                <p className='bg_p red'>
                  X <span>{sumBettingDiv}</span>
                </p>
              </td>
            </tr>
            <tr>
              <th>적중금액</th>
              <td>
                <p className='bg_p'>{moneyConvert(moneyWin, true)}</p>
              </td>
            </tr>
            <tr>
              <th>배팅금액</th>
              <td>
                <input
                  type='string'
                  onChange={handleChangeBetMoney}
                  className='betmoney'
                  value={moneyConvert(moneyBet, true)}
                />
              </td>
            </tr>
          </S.Tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonMoneyWrappper>
            {dataButtonMoney.map((item, index) => (
              <Button4
                onClick={(e: any) => handleClickButtonMoney(e, item.value)}
                key={index}
              >
                {item.text}
              </Button4>
            ))}
          </S.ButtonMoneyWrappper>
          <S.ButtonResetWrapper>
            <Button5 onClick={handleReset}>초기화</Button5>
            <Button5 onClick={handleMaxMoneyBet}>최대</Button5>
          </S.ButtonResetWrapper>
          <S.ButtonSubmitWrapper>
            <Button6 onClick={handleBetting}>배팅</Button6>
          </S.ButtonSubmitWrapper>
        </S.ButtonWrapper>
        {dataBetting.length > 0 && (
          <S.ListBetting>
            {dataBetting.map((item: any, index: number) => (
              <div key={`${item.idx}_${index}`} className='inner'>
                <div className='top'>{item?.nameElement}</div>
                <div className='bottom'>
                  <button
                    onClick={(e) => handleDeleteOneBetting(e, item?.idx)}
                    className='btn'
                  >
                    선택삭제
                  </button>
                  <span>{item.ratio}</span>
                </div>
              </div>
            ))}
          </S.ListBetting>
        )}
      </S.Betting>
    </S.Wrapper>
  )
}

export default BetSlipSportsGame
