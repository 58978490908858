import React from 'react' //lazy
import { Pagination } from 'antd'
import type { PaginationProps } from 'antd'

import { Layout } from 'antd'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";

import * as S from './style'

interface Props {
  className?: string
  total?: number | undefined
  pageSize?: number | undefined
  current?: number | undefined
  onChange?: any
}

const PartnerPagination = (props: Props) => {
  const { className, total, pageSize, current, onChange } = props

  const onChangePagi: PaginationProps['onChange'] = (pageNumber) => {
    // console.log("Page: ", pageNumber);
    onChange?.(pageNumber)
  }

  return (
    <S.Pagi className={className}>
      <Pagination
        showQuickJumper={false}
        total={total ?? 10}
        pageSize={pageSize ?? 10}
        current={current ?? 1}
        onChange={onChangePagi}
      />
    </S.Pagi>
  )
}

export default PartnerPagination
