import styled, { css } from 'styled-components'
import theme from 'common/style/styleSetup'
export const Header = styled.div`
  // background-color: #050511;
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  position: relative;
  z-index: 2;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* body .navbar-overlay { */
  /* @media screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0; */
  /* width: 100%;
      height: 100%; */
  /* width: 100vw;
    height: 100vh; */
  /* visibility: visible; */
  /* opacity: 1; */
  /* transition: 0.3s; */
  /* } */
  /* } */
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0px;
  }
`
export const Row = styled.div`
  display: flex;
`
export const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const Info = styled.div`
  color: yellow;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 20px;
  }
  span {
    color: white;
  }
`
export const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove yellow;
  border-radius: 10px;
  width: 100px;
  justify-content: center;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: olive;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em olive;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const ContentLogOut = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove red;
  border-radius: 10px;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: #800000;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em #800000;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const AvatarImg = styled.div`
  text-algin: center;
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  margin: 0px;
  img {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  // border-top: solid 1px 1px ${theme.bg[9]};
  // border-bottom: solid 1px 1px ${theme.bg[9]};
  // background-image: linear-gradient(#23344a, #13253e);
  // box-shadow: inset 0 3px 0 #0e151e;
`

export const WrapperTop = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    height: 43px;
    padding: 0 10px;
    justify-content: space-between;
  }

  @media screen and (min-width: 700px) {
    width: 90%;
  }

  @media screen and (min-width: 1500px) {
    width: 1274px;
  }
  position: static;
`

export const MenuWraper = styled.div`
  // background-color: white;
  // border: 4px solid green;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`
export const MenuGroupList = styled.div<{ show?: boolean | undefined }>`
  background-color: white;

  /* @media screen and (min-width: 768px) {
    display: none;
  } */
  ${(props) =>
    props.show &&
    css`
      @media screen and (max-width: 1200px) {
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        z-index: 999;
        top: -40px;
        left: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    `}
`

export const TopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    .logo {
      width: 280px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 768px) {
    .logo {
      width: 200px;
      object-fit: contain;
    }
  }

  @keyframes logoAkAnim {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    5% {
      opacity: 0;
      transform: translateY(50%);
    }
    15% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }
  img {
    width: 100%;
    animation: logoAkAnim 8s ease infinite;
    // height: 80px;
    @media screen and (max-width: 424px) {
      height: 60px;
    }
  }
`
export const TopButton = styled.div<{ show?: boolean | undefined }>`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .mobile-back {
    padding: 40px 20px 20px 20px;
    width: 100%;
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);
    display: flex;
    justify-content: flex-end;
    svg {
      font-size: 20px;
      cursor: pointer;
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;

    ${(props) =>
      props.show &&
      css`
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 10px;
        align-items: flex-start;
        top: -40px;
        padding: 24px;
        right: -10px;
        z-index: 999;
        background-color: #fff;
        width: 60%;
        height: calc(100vh + 36px);
        border-radius: 10px;
      `}
  }
`

export const WrapperBot = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .item {
    color: ${theme.color[7]};
    width: 10%;
    height: 24px;
    padding: 0 12px;
    text-align: center;
    border-left: 1px solid ${theme.bg[15]};
    justify-content: center;
    display: flex;
    align-items: center;
    :nth-child(10) {
      border-right: 1px solid ${theme.bg[15]};
    }
    .content {
      width: 100%;
      height: 28px;
      padding-top: 1px;
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      :hover {
        background: ${theme.bg[16]};
        border: solid 1px ${theme.bg[17]};
      }
    }
  }
`

export const BtnMenu = styled.div`
  cursor: pointer;
  margin-left: 24px;
`

export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #001035;
  font-size: 16px;
  padding: 23px 20px;

  @media (max-width: 729px) {
    margin-left: 25px;
    height: 40px;
    padding: 23px 19.11px 23px 40px;
    width: 80%;
    // border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    span {
      font-weight: bold;
    }
  }

  &.active {
    border-bottom: 2px solid #b98f67;
    color: #b98f67;

    img {
      filter: brightness(2) invert(60%);
    }
  }

  &:hover {
    background: #e9c8a3;
    color: #ffffff;

    img {
      filter: brightness(0) invert(1);
    }
  }
`
export const BoxItemP = styled.p``
