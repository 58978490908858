import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as S from './style'
import { useDispatch, useSelector } from 'react-redux'
import moneyConvert, { moneyConvertToNumber } from 'utils/moneyConvert'
import CoundownCustom from './Countdown'
import { useParams } from 'react-router-dom'
import { bettingGame, meApi } from 'apis'
import { fetchHistoryBetting } from 'store/actions/historyDetailBettingAction'
import miniGameAction from 'store/actions/miniGameAction'
import { UrlInternal } from 'common/constants/endpoints'
import { Button4, Button5, Button6 } from 'components'
import types from 'store/actions/types'

type Props = {}

const dataButtonMoney = [
  { value: 10000, text: '1만' },
  { value: 50000, text: '5만' },
  { value: 100000, text: '10만' },
  { value: 500000, text: '50만' },
  { value: 1000000, text: '100만' },
  { value: 5000000, text: '500만' }
]

function BestSlipMiniGame(): React.ReactElement {
  const { game_round, game_no } = useSelector(
    (state: any) => state?.infoMinigame
  )
  const { pick_val, bettingdiv, pick_gubun, select_pick } = useSelector(
    (state: any) => state?.pickValue
  )
  const dataMe = useSelector((state: any) => state.user)

  const { id }: { id: string } = useParams()

  const [betMoney, setBetMoney] = useState(0)
  const [bettingwin, setBettingwin] = useState(0)
  const [restMoney, setRestMoney] = useState(0)
  const dispatch = useDispatch()
  const modalRef = useRef<any>()
  const path = window.location.pathname

  const handleChangeInput = (e: any) => {
    const { value } = e.target
    const { mb_money } = dataMe.dataUser

    if (!isNaN(moneyConvertToNumber(value))) {
      const isAllowBet = mb_money - moneyConvertToNumber(value) > 0
      if (!isAllowBet) {
        return dispatch({
          type: types.POPUP_ACTION_OPEN,
          payload: {
            msg: `배팅할 보유머니가 부족합니다..`,
            typePopup: 'CONFIRM'
          }
        })
      }
      setBetMoney(moneyConvertToNumber(value))
    }
  }

  const handleReset = () => {
    setBetMoney(0)
    setBettingwin(0)
    setRestMoney(0)
  }

  const handleClickButtonMoney = (money: number) => {
    const { mb_money } = dataMe.dataUser
    const isMoneyLeft = mb_money - (betMoney + money) > 0

    if (!isMoneyLeft) {
      return dispatch({
        type: types.POPUP_ACTION_OPEN,
        payload: {
          msg: `배팅할 보유머니가 부족합니다..`,
          typePopup: 'CONFIRM'
        }
      })
    }

    setBetMoney((prev) => prev + money)
  }

  const loadDataUser = async () => {
    dispatch({ type: 'WAITTING' })
    try {
      const res: any = await meApi()
      if (res?.result === false) {
        alert(res?.message)
        dispatch({ type: 'STOP_WAITTING' })
        return
      }
      if (res?.data) {
        dispatch({ type: 'STOP_WAITTING' })
        dispatch({
          type: types.GET_DATA_USER,
          dataUser: res.data
        })
        return
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'STOP_WAITTING' })
    }
  }

  const handleBetting = async () => {
    if (betMoney <= 0) {
      return window.alert('배팅할 항목이 선택되지 않았습니다. ')
    }

    if (
      window.confirm(`베팅금액 [${betMoney}] 베팅게임 [  ] 베팅 하시겠습니까?`)
    ) {
      if (bettingdiv <= 0) {
        return window.alert(`배팅할 항목이 선택되지 않았습니다. `)
      }

      const pick = [id, game_round, pick_gubun, select_pick].join('|')

      const payload = {
        type: id,
        gmoney: dataMe?.dataUser?.mb_money,
        betMoney: betMoney,
        bettingcnt: 0,
        bettingdiv: bettingdiv,
        bettingwin: bettingwin,
        pick_gubun: pick_gubun,
        select_pick: select_pick,
        game_no: game_no,
        pick: pick
      }

      try {
        const res: any = await bettingGame(payload)

        if (res.error) {
          if (!res?.error?.result) {
            return alert(res?.error?.message)
          }
        }

        const params = {
          type: id,
          limit: 10,
          page: 1
        }

        const payloadPickValue = {
          pick_val: '',
          bettingdiv: 0,
          pick_gubun: '',
          select_pick: 0
        }
        alert(res.message)
        dispatch(fetchHistoryBetting(params))
        dispatch(miniGameAction.getPickValue(payloadPickValue))
        await loadDataUser()
        handleReset()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkPathMiniGame = useMemo(() => {
    const convertPathNameToArray = path.split('/')

    const convertPathString =
      convertPathNameToArray
        .slice(0, convertPathNameToArray.length - 1)
        .join('/') + '/'

    if (convertPathString === UrlInternal.MINI_GAME_DETAIL_ROUTER) {
      return true
    }

    return false
  }, [path])

  useEffect(() => {
    if (betMoney > 0) {
      const { mb_money } = dataMe.dataUser
      setRestMoney(mb_money - betMoney)
    }
  }, [betMoney, dataMe])

  useEffect(() => {
    setBettingwin(betMoney * bettingdiv)
  }, [bettingdiv, betMoney])

  if (!checkPathMiniGame) return <></>

  return (
    <S.Wrapper>
      <p className='bestlip'>BETSLIP</p>
      <S.InfoBetWrapper>
        <S.InfoBetColumn>
          <div className='item'>회 차</div>
          <div className='item'>
            <span>{game_round}</span> 회
          </div>
        </S.InfoBetColumn>
        <S.InfoBetColumn>
          <div className='item'>남은 시간</div>
          <div className='item'>
            <CoundownCustom />
          </div>
        </S.InfoBetColumn>
        <S.InfoBetColumn>
          <div className='item'>배 팅</div>
          <div className='item'>{pick_val}</div>
        </S.InfoBetColumn>
      </S.InfoBetWrapper>

      <S.FormInputWrapper>
        <S.InputWrapper className='nBillMoney'>
          <p>적중금액</p>
          <span>{moneyConvert(bettingwin, true)}</span>
        </S.InputWrapper>
        <S.InputWrapper>
          <p>배팅금액</p>
          <input
            type='string'
            onChange={handleChangeInput}
            value={moneyConvert(betMoney, true)}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <p>보유금액</p>
          <span>{moneyConvert(restMoney, true)}</span>
        </S.InputWrapper>
      </S.FormInputWrapper>

      <S.ButtonMoneyWrapper>
        {dataButtonMoney.map((item, index) => (
          <Button4
            onClick={() => handleClickButtonMoney(item.value)}
            key={index}
          >
            {item.text}
          </Button4>
        ))}
      </S.ButtonMoneyWrapper>

      <S.ButtonSubmit>
        <Button5 onClick={handleReset}>정정하기</Button5>
        <Button6 onClick={handleBetting}>베팅하기</Button6>
      </S.ButtonSubmit>
    </S.Wrapper>
  )
}

export default BestSlipMiniGame
